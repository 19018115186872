import React from 'react'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'

const Page = () => (
  <Content
    heading="ContentBackground"
    subHeading="Verkkopalvelu"
  >
    <Playground format="html">
      <div
        className="content-background content-background-gradient"
        style={{ height: '300px' }}
      >
        <h1 className="text-center">Otsikko</h1>
      </div>
    </Playground>
    <Playground format="html">
      <div
        className="content-background content-background-light-gradient"
        style={{ height: '300px' }}
      >
        <h1 className="text-center">Otsikko</h1>
      </div>
    </Playground>
    <Playground format="html">
      <div
        className="content-background content-background-gradient-with-decoration mt-layout-md"
        style={{ height: '300px' }}
      >
        <h1 className="text-center">Otsikko</h1>
      </div>
    </Playground>
    <Playground format="html">
      <div
        className="content-background content-background-primary-with-decoration mt-layout-md"
        style={{ height: '300px' }}
      >
        <h1 className="text-center">Otsikko</h1>
      </div>
    </Playground>
    <Playground format="html">
      <div
        className="content-background content-background-light-gradient-with-decoration mt-layout-md"
        style={{ height: '300px' }}
      >
        <h1 className="text-center">Otsikko</h1>
      </div>
    </Playground>
  </Content>
)

export default Page
